<!--
 * @Author: jinqingquan 2842427165@qq.com
 * @Date: 2023-09-19 14:27:39
 * @LastEditors: jinqingquan 2842427165@qq.com
 * @LastEditTime: 2024-04-19 11:57:15
 * @FilePath: \xjd_h5\src\view\refund\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div style="padding-bottom:30px;height: 100vh;overflow: scroll; box-sizing: border-box;" ref="boss">
        <div class="tiptitle"><span></span>填写要求说明</div>
        <div class="imgbox">
            <img :src="img" alt="" @click="imgshow">
        </div>
        <div class="tiptitle"><span></span> 收据 <span>请根据上图的填写要求上传数据</span></div>
        <div class="upload-box">
            <Uploader multiple :max-count="5" :max-size="20 * 1024 * 1024" accept=".pdf" @oversize="onOversize"
                :after-read="afterRead">
                <!-- <Button icon="plus" type="primary">选择上传文件</Button> -->
                <div class="primary-button">点击上传文件</div>
                <div class="desc-tip">只能上传pdf格式文件，文件不能超过20mb</div>
            </Uploader>
        </div>
        <div class="file-list" v-if="fileList.length">
            <div class="file-list-box">
                <div class="file-list-item" v-for="(item, index) in fileList" :key="index">
                    <div class="file-list-name">{{ item.originalName }}</div>
                    <Icon
                        name="https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E6%B5%B7%E6%BD%AE/%E5%B0%8F%E5%BE%AE%E5%B7%A5%E7%A8%8B-%E5%B0%8F%E7%A8%8B%E5%BA%8F/close.png"
                        @click="(e) => deleteFile(index, e)" size="16" />
                </div>
            </div>
        </div>
        <div class="btn" @click="submitBtn">提交</div>
    <Overlay :show="uploadLoading">
      <div style="position: absolute;top:50%;left:50%;transform: translate(-50%, -50%);">
        <div v-if="uploadFail" style="color:#fff;">上传失败</div>
        <Loading vertical style="color:#fff; font-size:20px;font-weight:500" v-else>上传中...</Loading>
      </div>
    </Overlay>
    </div>
</template>

<script>
import { Uploader, Button, Icon, Field, ImagePreview,Overlay,Loading} from 'vant';

import axios from 'axios';
import { Toast } from 'vant'
export default {
    components: {
        Uploader, Button, Icon, Field,Overlay,Loading
    },
    data() {
        return {
            enrollId: '',
            fileList: [],
            token: '',
            uploadFail:false,
            uploadLoading:false,
            type:'',
            img: 'https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E6%B0%B8%E7%A6%8F%E9%A9%BE%E9%A9%B6%E8%88%B1/%E9%80%80%E6%AC%BE%E6%A8%A1%E6%9D%BF.png'
        }
    },
    mounted() {
        let { query } = this.$route
        let { token, enrollId,type} = query
        this.token = token
        this.enrollId = enrollId
        this.type=type
    },
    methods: {
        imgshow() {
            ImagePreview([this.img])
        },
        onOversize(file) {
            Toast('文件大小不能超过20mb')
        },
        submitBtn() {
            let { enrollId, fileList,type} = this
            if (!fileList.length) {
                return Toast("请上传收据文件")
            }
            axios.defaults.headers = {
                'Blade-Auth': this.token
            };
            axios
                .post('/meiyun-smallengineering/project/summary/applyRefund',
                    { enrollId, refundFile: this.fileList.map(i => i.link).join(',') })
                .then((res) => {
                    console.log(res)
                    if (res.data.success) {
                        Toast.success('提交成功');
                        setTimeout(() => {
                            wx.miniProgram.redirectTo({
                            url: `/pages/refund/index`,
                        })
                        }, 1500);
                    }
                }).catch(err => {
                    // wx.miniProgram.redirectTo({
                    //   url: '/pages/tempjump/index?type='+this.type+'&returnData='+JSON.stringify({data:'sdddd'}),
                    // })
                });
        },
        deleteFile(index, e) {
            let { fileList } = this
            fileList.splice(index, 1)
            this.fileList = [...fileList]
            this.$nextTick(() => {
                let scrollEl = this.$refs.boss;
                scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: 'smooth' });
            });
            // wx.miniProgram.postMessage({ data: JSON.stringify(this.fileList) });
        },
        afterRead(file) {
            this.uploadLoading = true

            let formdata = new FormData()
            formdata.append('file', file.file)
            axios.defaults.headers = {
                'Blade-Auth': this.token,
                'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
            };
            axios
                .post('/meiyun-smallengineering/file/put-file', formdata)
                .then((res) => {
                    this.uploadLoading = false
                    if(this.fileList.length==1){
                        this.fileList.splice(-1, 1)
                    }
                    this.fileList.push(res.data.data)
                    this.$nextTick(() => {
                        let scrollEl = this.$refs.boss;
                        scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: 'smooth' });
                    });
                    // wx.miniProgram.postMessage({ data: {data:JSON.stringify(this.fileList)} });
                }).catch(()=>{
                    this.uploadFail = true;
                    setTimeout(()=>{
                        this.uploadLoading = false
                        this.uploadFail = false
                    }, 1500)
                });
        },
    }
}
</script>

<style lang="scss" scoped>
.btn {

    width: 343px;
    height: 48px;
    background: #1677FF;
    border-radius: 2px;
    text-align: center;
    margin: 20px auto;
    margin-top: 50px;
    margin-bottom: 30px;
    line-height: 48px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
}

.imgbox {
    padding: 16px;

    img {
        width: 343px;
        height: 300px;
        background: #D8D8D8;
        border-radius: 4px;
    }
}

.tiptitle {
    box-sizing: border-box;
    width: 100vw;
    padding: 8px;
    display: flex;
    align-items: center;
    font-size: 14px;
    border-bottom: 1px solid #eee;
}

.tiptitle span:nth-child(1) {
    width: 4px;
    height: 16px;
    background: #1677FF;
    border-radius: 2px;
    margin-right: 3px;
}

.tiptitle span:nth-child(2) {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    margin-left: 5px;
}

.title {
    width: 100vw;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    border-bottom: 2px solid #EEEEEE;
    padding: 18px;

    span {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        margin-left: 4px;
    }
}

.primary-button {
    color: #1677FF;
    border-radius: 8px;
    width: 100vw;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #1677FF;
    text-align: center;
    margin-top: 23px;
}

.desc-tip {
    width: 100vw;
    font-size: 12px;
    color: #999;
    margin-top: 4px;
    text-align: center;
    margin-bottom: 10px;

}

.file-list {
    padding: 12px 12px;

    &-box {
        border: 1px solid #eee;
        border-radius: 4px;
        max-height: 30vh;
        overflow-y: auto;
    }

    &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        color: #1677FF;
        padding: 20px 12px;

        &+& {
            border-top: 1px solid #eee;
        }
    }

    &-name {
        max-width: 300px;
        text-align: left;
        word-wrap: break-word;
    }
}
</style>